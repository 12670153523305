<template>
  <div class="section">
    <g-picture
      v-if="data.picture && pageType != 'project'"
      class="section__picture-outer"
      :content="data.picture"
      :key="data.label"
    />

    <div class="section-inner">
      <div>
        <div
          class="section__category"
          v-if="pageType === 'project' && data.types"
        >
          <SvgSprite
            class="section__category__icon"
            :symbol="`projects-${data.types[0].value}`"
            size="20"
          />
          <span class="h5">{{ data.types[0].label }}</span>

          <span v-if="pageType === 'project' && isNew" class="label-new"
            >New</span
          >
        </div>

        <div class="section__title h4" v-if="data.label || data.title">
          {{ data.label || data.title }}
        </div>

        <div class="section__text" v-if="data.lead">
          {{ data.lead }}
        </div>

        <div class="section__location" v-if="data.location">
          <SvgSprite
            class="section__location__svg"
            symbol="ui-location"
            size="18 18"
          />
          <span>{{ data.location }}</span>
        </div>
      </div>
      <SvgSprite class="icon-arrow" symbol="ui-arrow-right" size="16 16" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    data: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: {} as any,
    },
    pageType: String,
  },
  setup(props) {
    const isNew = computed(() => {
      const today = moment().startOf('day')
      const publish = moment.unix(props.data.date).format('MM/DD/YYYY')

      if (today.diff(publish, 'days') < 30) {
        return true
      }

      return false
    })

    return {
      isNew,
    }
  },
})
</script>

<style lang="scss" scoped>
$p: (
  xxs: 15px,
  l: 20px,
);

.section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: $c-white;
  position: relative;
  cursor: pointer;

  > div {
    width: 100%;
  }

  p {
    transition: opacity 0.3s;
  }

  .icon-arrow {
    stroke: $tangerine;
    stroke-width: 2;

    transition: transform 0.3s;
  }

  &:hover {
    p {
      opacity: 0.6;
    }

    .icon-arrow {
      transform: translate(0, -50%) translateX(3px);
    }
  }
}

.section-inner {
  @include fluid(padding-top, $p);
  @include fluid(padding-right, $p);
  @include fluid(padding-bottom, $p);
  @include fluid(padding-left, $p);

  position: relative;
  display: flex;
  align-items: center;

  > div {
    padding-right: 1.5rem;
  }
}

.icon-arrow {
  @include center-y;
  @include fluid(right, $p);
}

.section__title {
  font-weight: bold;
  @include fluid(
    font-size,
    (
      xxs: 14px,
      xl: 18px,
    )
  );
}

.section__text {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.4em;
}

.section__category {
  @include fira-bold;

  margin-bottom: 1.8rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.4rem;
  }
}

.section__location {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  color: $hippie-blue;
  font-size: 1.2rem;

  svg {
    margin-right: 0.4rem;
  }
}

.label-new {
  @include label-new;
}
</style>
