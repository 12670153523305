<template>
  <div class="category">
    <img v-if="data.icon" :src="data.icon" class="icon" />
    <SvgSprite class="icon-arrow" symbol="ui-arrow-right" size="16 16" />

    <p>{{ data.label }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    data: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: {} as any,
    },
  },
})
</script>

<style lang="scss" scoped>
$p: (
  xxs: 15px,
  l: 20px,
);

.category {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  background: $c-white;
  @include fluid(
    height,
    (
      xxs: 107px,
      l: 150px,
    )
  );
  @include fluid(padding-top, $p);
  @include fluid(padding-right, $p);
  @include fluid(padding-bottom, $p);
  @include fluid(padding-left, $p);

  .icon,
  p {
    transition: opacity 0.3s;
  }

  .icon-arrow {
    stroke: $tangerine;
    stroke-width: 2;

    transition: transform 0.3s;
  }

  &:hover {
    .icon,
    p {
      opacity: 0.6;
    }

    .icon-arrow {
      transform: translateX(3px);
    }
  }
}

.icon {
  @include fluid(
    width,
    (
      xxs: 36px,
      l: 48px,
    )
  );
  height: auto;

  margin-top: -5px;
  margin-left: -5px;
}

.icon-arrow {
  position: absolute;
  @include fluid(right, $p);
  @include fluid(bottom, $p);
}

p {
  margin-top: auto;
  padding-right: 1.5rem;
  color: $regal-blue;
  font-weight: bold;
  @include fluid(
    font-size,
    (
      xxs: 14px,
      xl: 18px,
    )
  );
}
</style>
