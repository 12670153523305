
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    data: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: {} as any,
    },
  },
})
