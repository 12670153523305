
import { computed, defineComponent } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    data: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: {} as any,
    },
    pageType: String,
  },
  setup(props) {
    const isNew = computed(() => {
      const today = moment().startOf('day')
      const publish = moment.unix(props.data.date).format('MM/DD/YYYY')

      if (today.diff(publish, 'days') < 30) {
        return true
      }

      return false
    })

    return {
      isNew,
    }
  },
})
